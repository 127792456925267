    :root, [data-bs-theme=light]{
        --bs-border-color:#616364;
        --tw-ring-offset-shadow: 0 0 #0000;
        --tw-ring-shadow: 0 0 #0000;
 
     --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
    }

    .ptusa{
        padding-top: 3%;
    }

    .main-head-wrap{
        background-color: #0F172A;
        display: flex;
   z-index: 999999;
        font-size: 12px;
        height: 3.5rem;
        align-items: center;
        transition: all 0.4s ease-In-out;
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        right: 0;
        box-shadow: 0 6px 40px rgba(0, 0, 0, 0.11);

        top: 0;
    }
    .main-head-wrap1{
        /* background-color: #0F172A; */
        display: flex;
   z-index: 1000;
        font-size: 12px;
        /* height: 3.5rem; */
        align-items: center;
        overflow-y: auto;
        transition: all 0.4s ease-In-out;
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        right: 0;
        /* box-shadow: 0 6px 40px rgba(0, 0, 0, 0.11); */

        top: 9%;
    }
    h1,h2,h3,h4,h5,h6{
        color: #000;
    }
    .nav-item.nested{
        color: #fff;
        list-style: none;
        font-size: .9rem;
        padding: 15px 8px;
        font-style: bold;
        font-weight: 600;
    }
    .bg-img{
        background-image: url(./../img/banner.png);
        height: 440px;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .font-bold {
        font-weight: 800;
    }
    .wrap{


        height: 100%;
        padding: 10px;
        border-radius: 0px;
        background-color: #fff;
    }
    .hometools{
        background-color: #F4F8FE;

    }

    .header-wrap{
        position: relative;
        z-index: 99999;
    }
    .img img{
    padding: 0;
    height: auto;
    max-width: 100%;
    }
    .menu-item{
        list-style: none;
    }
    .menu-item {
        padding: 15px 10px;
    }
    .item-wrawp{
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
    }
    .navbtn::before{
        content: "";
        position: absolute;
        right: -100%;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background: #000;
        transition: all .4s ease-in-out;
        opacity: 0;
        z-index: -15!important;
        transform: skewX(-60deg);
    }
    .navbtn::after{
        font-size: 20px;
        margin-right: 10px;
    }

        .menu-item {
            padding: 15px 10px;
            color: white;
        }

    .item-wrawp{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nestedmenu ul{
        padding-left: 0px !important;
    }

    .nestedmenu ul li {
        color: #303331;
        list-style: none;
        padding: 8px 0px 0px 0px;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        position: relative;
        text-align: left;
        transition: .25s;
        gap: 11px;
        align-items: flex-start;
    }
    .text-slate-800 {
        --tw-text-opacity: 0.8;
        color: rgb(30 41 59 / var(--tw-text-opacity)) !important;
    }
    .nestedmenu-2 ul li{

        color: #303331;
        list-style: none;
        padding: 6px;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        position: relative;
        text-align: left;
        align-items: center !important;
        transition: .25s;
        gap: 11px;
        align-items: flex-start;

    }
    .nestedmenu-2 ul li p{
        margin-top: 0px;
    }
    a{
        text-decoration: none;
    }
    .nestedmenu-2 h4 {
        padding-left: 30px;
    }

    .nestedmenu-2 h4 {
        font-size: 20px;

        font-weight: 600;
        text-align: left;
    }
    .nestedmenu-3 ul li{
    
        color: #303331;
        list-style: none;
        padding: 13px 0px 0px 0px;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        position: relative;
        
        text-align: left;
        transition: .25s;
        gap: 11px;
        align-items: flex-start;

    }
    .nestedmenu-3 h4 {
        padding-left: 30px;
    }
    .smallh{
        font-size: 1rem;
        font-weight: 600;
    line-height: 1.25rem;
    color: rgb(30 41 59 / var(--tw-text-opacity));
    --tw-text-opacity:1;
    margin-bottom: 0px;
    padding-bottom: 0px;
    text-align: center;
    font-weight: bold;
    }
    .smallp{
        font-size: 10px;
    font-weight: 600;

    color:rgb(71 85 105 / var(--tw-text-opacity));
    --tw-text-opacity:1;
    max-width: 11rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
    }
    .nestedmenu-3 ul{
      
        padding-left: 10px!important;
    }
    .nestedmenu-3 h4 {
        font-size: 20px;
        color: #000;
        font-weight: 600;
        text-align: left;
    }

    .nestedmenu{
        background-color: #fff;
        position: absolute;
        top: 53px;
        right: 0.6%;
        /* border-bottom-left-radius: 10px;  */
        /* Set border radius for bottom left corner */
        border-bottom-right-radius: 10px; 
        border-top: 1px solid #f1f1f1;
    visibility: hidden;
    z-index: 99999999;

    opacity: 0;
    height: auto;
    padding: 10px;
    width: auto;

    }
    .nested-head{
        color: rgb(30 41 59 / var(--tw-text-opacity));
        --tw-text-opacity: 1;
    }
    .nestedmenu-4{
        background-color: #fff;
        position: absolute;
        z-index: 9999999;
        top: 53px;
        border-bottom-left-radius: 10px; /* Set border radius for bottom left corner */
        border-bottom-right-radius: 10px; 
        border-top: 1px solid #f1f1f1;
    visibility: hidden;


    opacity: 0;
    height: auto;
    padding: 10px;
    width: auto;

    }
    .nestedmenu-4 ul{
        padding-left: 0px !important;
    }
    .nestedmenu-4 ul li{

        list-style: none;

        font-size: 14px;
        font-weight: 400;

        position: relative;
        color: #c7c8ca;
        text-align: left;
        padding: 10px;

    
    }
    .menu-item .nav-link{
        font-size: 14px !important;; 
        font-weight: 500  !important;; 
        font-weight: 600;
    }
    .nestedmenu-5 ul{
        padding-left: 0px !important;
    }
    .nestedmenu-5 ul li{
    

        list-style: none;

        font-size: 14px;
        font-weight: 400;

        position: relative;
        color: #c7c8ca;
        text-align: left;
        padding: 10px;

    
    }
    .nestedmenu-5{
        background-color: #fff;
        position: absolute;
        top: 53px;
        border-bottom-left-radius: 10px; /* Set border radius for bottom left corner */
        border-bottom-right-radius: 10px; 
        border-top: 1px solid #f1f1f1;
    visibility: hidden;


    opacity: 0;
    height: auto;
    padding: 10px;
    width: auto;

    }
    .nestedmenu-2{
        background-color: #fff;
        position: absolute;
        top: 53px;
    
        border-top: 1px solid #f1f1f1;
    visibility: hidden;

    border-bottom-left-radius: 10px; /* Set border radius for bottom left corner */
    border-bottom-right-radius: 10px; 
    opacity: 0;
    height: auto;
    padding: 10px;
    width: auto;

    }
    .nestedmenu-3{
        background-color: #fff;
        position: absolute;
        top: 53px;
        border-bottom-left-radius: 10px; /* Set border radius for bottom left corner */
        /* border-bottom-right-radius: 10px;  */
        border-top: 1px solid #f1f1f1;
    visibility: hidden;


    opacity: 0;
    height: 226px;
    padding: 10px;
    width: auto;

    }
    .nested-wrap{
        padding: 0 30px;
    }
.nestedmenu.show {
        visibility: visible;
        opacity: 1;
        z-index: 9999;
    }
     .nestedmenu-2.show {
        visibility: visible;
        opacity: 1;
        z-index: 9999;
    }
     .nestedmenu-3.show {
        visibility: visible;
        opacity: 1;
        z-index: 9999;
    }
     .nestedmenu-4.show {
        visibility: visible;
        opacity: 1;
        z-index: 9999;
    }
     .nestedmenu-5.show {
        visibility: visible;
        opacity: 1;
        z-index: 9999;
    }
   /* Add a CSS transition for the icon rotation */
   .rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
  .rotate-0{
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }
    .mested3-wrap{
        padding: 0 30px;
    }

    /* overlay banner */



    /* cards */
    .pricing-section.style-two {
        background-color: #ebf0f9;
    }

    .pricing-section .ribbon {
        position: absolute;
        top: -10px;
        right: 19px;
        background: #222;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        font-family: var(--fira-sans);
        padding: 0 11px;
        border-radius: 10px;
        line-height: 21px;
        background-color: #ebf0f9;
    }

    .pricing-block.style-two {
        padding: 0 10px;
    }

    .pricing-section.style-two .pricing-content .wrapper-box {
        -webkit-box-shadow: none;
                box-shadow: none;
    }

    .pricing-block.style-two .inner-box {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                /* -webkit-box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.08); */
        /* box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.08); */
        background: #fff;
        border: 2px solid blue;
        border-radius: 7px;
        }
    .pricing-block.style-two .inner-box1 {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                /* -webkit-box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.08); */
        /* box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.08); */
        border-radius: 10px;
        }

    .pricing-block.style-two .top-content {
        background-color: #231933;
    }

    .pricing-block.style-two .top-content .category {
        color: #fff;
    }

    .pricing-block.style-two .top-content .price {
        color: #fff;
    }

    .pricing-block.style-two.active .lower-content h5 {
        background-color: #231933;
    }

    .pricing-block.style-two.active .lower-content h5::before {
        background-color: #261c36;
    }

    .pricing-block.style-two.active .lower-content h5::after {
        background-color: #261c36;
    }

    .pricing-block.style-two .lower-content ul li {
        padding-left: 0;
        font-weight: 500;
    }

    .pricing-block.style-two .lower-content h4 {
        font-family: inherit;
        margin-bottom: 0px !important;
    }
    .bkh-rating-table tr td:first-child {
        margin: 0 10px;
        background: transparent;
    }
    

.bkh-rating-table tr td:first-child {
    margin: 0 10px;
    background: transparent;
}

.bkh-rating-table tr td {
    background: #f9fafb;
    padding: 0 9px;
    border: none;
    vertical-align: middle;
}
.cb-forex-logo-area table, th, td {
    border: 1px solid #dfe9f2;
}
.entry-content table, th, td {
    border: 1px solid #dfe9f2;
    color: #42423a;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 5px 0;
}
    .bkh-rating-table tr td .bkh-logo {
        border-top: 1px solid #eef2f4;
        border-bottom: 1px solid #eef2f4;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
    }
    .pricing-block .top-content {
        position: relative;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: rgb(243, 244, 247);
        padding: 25px 30px 39px;
    }

    .pricing-block .top-content .category {
        position: relative;
        font-size: 22px;
        font-weight: 600;
        font-family: var(--fira-sans);
    }

    .pricing-block .top-content .price {
        position: relative;
        font-size: 22px;
        font-weight: 600;
        font-family: var(--fira-sans);
    }

    .pricing-block .top-content .price span {
        font-size: 16px;
        position: relative;
        margin-right: 5px;
    }

    .pricing-block .top-content .price sub {
        font-size: 14px;
        font-weight: 400;
        margin-left: 5px;
        position: relative;
        bottom: 1px;
    }

    .pricing-block .lower-content {
        /* position: relative; */
        text-align: center;
        border-bottom-left-radius: 10px;
        padding: 0px 24px 10px;
    }

    .pricing-block .lower-content::before {
        position: absolute;
        content: "";
        right: 0px;
        top: 50px;
        bottom: 50px;
        width: 1px;
        background: rgb(225, 229, 233);
    }

    .pricing-block:last-child .lower-content::before {
        display: none;
    }

    .pricing-block .lower-content h5 {
        position: relative;
        font-size: 15px;
        border-radius: 15px;
        display: inline-block;
        color: rgb(255, 255, 255);
        font-weight: 500;
        -webkit-transform: translateY(-15px);
            -ms-transform: translateY(-15px);
                transform: translateY(-15px);
        font-family: inherit;
        padding: 6px 30px;
        background: rgb(32, 22, 48);
    }


    .pricing-block .lower-content h4 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        font-family: inherit;
    }

    .pricing-block .lower-content ul {
        position: relative;
        margin-bottom: 40px;
        text-align: left;
    }

    .pricing-block .lower-content ul li {
        position: relative;
        color: rgb(12, 41, 87);
        font-size: 18px;
        line-height: 54px;
        padding-left: 55px;
        border-bottom: 1px solid rgb(225, 229, 233);
    }

    .pricing-block .lower-content ul li i {
        margin-right: 10px;
        position: relative;
        top: 2px;
    }

    .pricing-block .lower-content ul li span i,
    .pricing-block .lower-content ul li span {
        color: rgb(165, 175, 191);
    }

    .pricing-block .lower-content .btn-style-two {
        -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 50px 0px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 50px 0px;
    }

    .pricing-block .lower-content .theme-btn {
        margin-bottom: 10px;
    }

    .pricing-block .lower-content .hint {
        position: relative;
        font-size: 16px;
        font-weight: 600;
    }
    td{
        display: flex;
        justify-content: center;
    }
tr{
    display: flex;

}
.bkh-rating-table tr td {
    background: #f9fafb;
    padding: 5px 9px;
    border: none;
    vertical-align: middle;
}

    .btn-style-two {
        /* position: relative; */
        display: inline-block;
        font-size: 16px;
        line-height: 30px;
        color: white;
        background: #2f5ce9;
        font-weight: 600;
        text-transform: capitalize;
        border-radius: 11px;
        overflow: hidden;
        vertical-align: middle;
        background-color: transparent;

    }
    .btn-style-two1{
        font-size: 16px;
        line-height: 30px;
        color: white  !important;
        background-color: #011dff !important;  
        font-weight: 600;
        text-transform: capitalize;
        border-radius: 11px;
        overflow: hidden;
        vertical-align: middle;
        background-color: transparent;
    }
    .btn-style-two1:hover{
        cursor:pointer;
        font-size: 16px;
        line-height: 30px;
        color: white  !important;
        background-color: rgb(0, 41, 165) !important;  
        font-weight: 600;
        text-transform: capitalize;
        border-radius: 11px;
        overflow: hidden;
        vertical-align: middle;
        background-color: transparent;
    }

    .btn-style-two:hover {
        color: #ffffff;
    }

    .btn-style-two .btn-title {
        /* position: relative; */
        display: block;
        z-index: 1;
        font-family: "Fira Sans", sans-serif;
        padding: 3px 16px;
        transition: all 0.3s ease 0s;
        border-radius: 7px;
        overflow: hidden;
        text-decoration: none;
        background-color: #011dff;
        transition: background-color 0.3s ease-in-out;

    }
    .faiz .faiz1{
        display: block;
        cursor: pointer;
        z-index: 1;
        line-height: 30px;
        color: white;
        font-family: "Fira Sans", sans-serif;
        padding: 3px 16px;
        transition: all 0.3s ease 0s;
        border-radius: 7px;
        overflow: hidden;
        text-decoration: none;
        background-color: #011dff;
        transition: background-color 0.3s ease-in-out;

    }
    .forhov:hover{
        /* border:1px solid #011dff; */
        cursor:pointer;
    }
    .hn{
        border-radius: 10px !important;
    }
    .hn:hover{
        box-shadow: 2px 2px 10px gray !important;
        border-radius: 10px !important;
    }
    /* /.btn-style-two .btn-title::before { */
        /* content: ''; */
        /* position: absolute; */
        /* left: 0px;
        bottom:0px;
        z-index:-1; */
        /* width: 100%;
        height:100%;
        background: transparent;
        box-shadow: inset 0px 0px 0px #010c20;
        display: block;
        -webkit-transition: all 0.4s cubic-bezier(.5, .24, 0, 1);
        transition: all 1s cubic-bezier(.5, .24, 0, 1) */
      /* }  */
      /* if you want it to show from the right use -300px instead */
      /* .btn-style-two .btn-title:hover::before {
       box-shadow: inset 300px 0px 0px #010c20;
       color: white;
      } */
  
    .theme-btn.btn-style-two{
        text-decoration: none;
    }
    /* .btn-style-two .btn-title2::before {
        content: '';
        position: absolute;
        left: 0px;
        bottom:0px;
        z-index:-1;
        width: 100%;
        height:100%;
        background: transparent;
        box-shadow: inset 0px 0px 0px #010c20;
        display: block;
        -webkit-transition: all 0.4s cubic-bezier(.5, .24, 0, 1);
        transition: all 1s cubic-bezier(.5, .24, 0, 1)
      }
      .btn-style-two .btn-title2:hover::before {
        box-shadow: inset 300px 0px 0px #010c20;
        color: white;
       } */
       .forhover {
        box-shadow: inset 000px 0px 0px 0px #000;
      -webkit-transition: all 0.6s cubic-bezier(.9, .24, .40, 1);
      transition: all 0.6s cubic-bezier(.9, .24, .40, 1)
    }
    
    .forhover::before {
        content: '';
        width: 100%;
        height: 0%;
        display: block;
        background: #000;
        position: absolute;
        left: 0%;
        opacity: 0;
        top: 0;
        z-index: -1;
        -webkit-transition: height .4s cubic-bezier(0.9, 1, 0.32, 1), opacity .1s ease;
        transition: height .4s cubic-bezier(0.9, 1, 0.32, 1), opacity .1s ease;
      
    }
    
    .forhover:hover::before {
        opacity: 1;
        height: 100%;
    }
    
    .forhover:hover {
        box-shadow: inset 00px 100px 0px 0px #000;
        color: #ffffff;
    }
    .btn-style-two :hover{
        border-radius:11px;
    }
    
     
    .btn-style-two .btn-title2 {
        /* position: relative; */
        display: block;
        z-index: 1;
        font-family: "Fira Sans", sans-serif;
        padding: 0px;
        justify-content: center;
        transition: all 0.3s ease 0s;
        border-radius: 7px;
        width: 146px;
        gap: 7px !important;
        height: 43px;
        overflow: hidden;
        text-decoration: none;
        background-color: #011dff;
        transition: background-color 0.3s ease-in-out;
     }
     .fontmain{
        font-weight: 600;
        
     }
     .btn .btn-style-two .btn-title2:hover{
        
        background-color: #260058;
        animation: scaleUp 0.3s ease-in-out;
     }
 
     @keyframes scaleUp {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.1);
        }
    }
     .lower-content h4{
        font-size: 15px;
     }
    .tradepara{
        font-size: 12px;
        color: gray;
        font-weight: 300;
    }
    .tradepara1{
        font-size: 12px;
        color: black;
        font-weight: 500;
    }
    .hint a{
text-decoration: none;
    }

    .btn-style-two:hover .btn-title {
        color: #ffffff;
    }
  
    .forhover {
        position: relative;
        overflow: hidden;
      /* Set the initial background color */
        color: #000;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    }
  
    .cursor-pointer:hover{
        background: #d4dbf045;
        border-radius: 0px;
        margin-top: 0px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1286px) {
        .desktop-none {
            display: none !important;
        }
    }
    .flex-reverse{
        flex-direction: row-reverse;
    }
    .cursor-pointer{
        cursor: pointer;
    }
   
    .icontool{
        background: #011DFF;
        padding: 11px;
        border-radius: 10px;
    }
    .icontool svg{
        font-size: 27px;
    color: white;
    }
    .pricing-block.style-two .lower-content h5
    {
        background-color: #011dff;
    }
    .fa-star-half-o {
        position: relative;
        color: rgb(10, 9, 4); /* Set the color for half stars to match full stars */
        -webkit-mask-image: linear-gradient(to right, #011dff 90%, gray 10%);
        mask-image: linear-gradient(to right, #011dff 90%, gray 10%);
      }
    .pricing-block .lower-content h5 {
        position: relative;
        font-size: 15px;
        display: inline-block;
        color: rgb(255, 255, 255);
        font-weight: 500;
        -webkit-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        transform: translateY(-15px);

        font-family: inherit;
        padding: 6px 30px;
        background: rgb(32, 22, 48);
    }

.menu-item:hover{
    background: #c9c4c43d;
    color: white;
}
.menu-item.active{
    background: #c9c4c43d;
    color: white;
}
    .main-rate .btn-ratting{
        width: 12px;
        padding: 5px 0;
        height: 28px;
        border-radius: 0px;
        display: flex;
        border: 1px solid white;
    }
    .main-rate {
        display: flex;
    }
    .text-left{
        text-align: left;
    }
    .text-light-gray{
    color: #606b77 ;
    text-align: left;
    font-weight: 400;
    }
    .bg-dark{
        background-color: #1E293B !important; 
    }
    .bg-darker{
        background-color: #0F172A;
    }
    .lower-content h4{
        font-size: 15px !important ;
font-weight: 700 !important;
    }

    .wp-block-table table {
        border-collapse: collapse;
        width: 100%;
    }

    table {
        border-collapse: separate;
        border-spacing: 0;
        border-width: 1px 0 0 1px;
        margin: 0 0 1.5em;
        width: 100%;
    }
    table, td, th {
        border: 1px solid var(--ast-border-color);
    }
    .wp-block-table td, .wp-block-table th {
        border: 1px solid;
        padding: 0.5em;
    }
    /* overlaybanner */
    .overlaybanner {
        position: absolute;
        width: 100%;
        height: 440px; /* Adjust the height as needed */
    
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }
    
    .overlaybanner::before {
        content: "";
        position: absolute;
        top: -25px;
        left: 0;
        width: 100%;
        height: 408px;
        background-size: 100% 100%;
        /* background-image:  url('/src/assets/img/banner_1.gif'); */
        z-index: 1;
        transform: skewY(350deg);
        transform-origin: top left;
        opacity: 1;
    }
    @keyframes colorChange {
        0% {
        color: initial; /* Start with the initial text color */
        }
        80% {
        color: initial; /* Keep the initial text color until the 8th second */
        }
        90% {
        color: white; /* Change text color to white on the 9th second */
        }
        100% {
        color: initial; /* Revert to initial text color on the 11th second */
        }
    }
    @media screen and (max-width: 425px) {
        .broker-name {
            font-size: 24px;
            /* font-weight: 600 !important; */
            text-align: center;
        }
    }
    @media only screen and (max-device-width:768px){
        .burger-icon{
            display: block !important;
        }
        .item-wrawp{
            display: none !important;
        }
        .mb-pos{
            position: absolute;

        }
        .mb-pos img{
            width: 200px !important;


        }
        .mb-mt{
            margin-top: 10px !important;
        }
    }
    @media screen and (max-width: 768px) {
        .mobile-review{
            width: 100%;
            height: 550px;
        }
        .world{
margin-left: 20px !important;
/* margin-top: 10px !important; */
        }
        .main-review-head{
            max-width: 60% !important;
        }
        .broker-name{
            font-size: 35px ;
            /* font-weight: 600 !important; */
            text-align: center;
        }
        .mobilere{
            width: 300px;
        }
        .posi{
            position: relative !important;
            left: 10px  !important;
        }
        .feeCalculator-heading{
            font-size: 35px !important;
        }
        .nm{
            width: 300px !important;
        }
        .review-select{
            /* margin: 0%; */
            display: flex;
            align-items: flex-start;
            width: 300px;
            justify-content: flex-start;
            flex-direction: column;
            position: relative;
            right: 23%;
        }
        .bn{
            width: 290px;
            margin-left: -14px;
        }
        /* body{
            overflow-x: hidden;
        } */
        
        /* Define styles for smaller screens here */
        /* .container {
        padding: 10px; 
        } */
        
        .table-container {
        overflow-x: auto !important; /* Enable horizontal scrolling for small screens */
        }
    
        table {
        width: 100% !important; /* Make the table occupy the full width */
        }
        .mb-p{
            width: 100% !important;
    
        }
        .mb-h{
            font-size: 44px !important;
        }
        th, td {
        padding: 8px !important; /* Add padding to cells for better readability */
        }
        .banner-head h1{
            position: relative;
            text-align: left;
            font-size: 44px !important;
            margin-top: 2rem !important;
        }
        .banner-head p{
            font-size: 12px !important;
            margin-bottom: 0px !important;
            max-width: 100% !important;
        
        }
        tr{
            display: flex;
    
        }
        .mb-none{
            display: none;
        }
        .maingap{
            gap: 30px !important;
        }
        /* Add any other responsive styles as needed */
    }
  
    tbody, td, tfoot, th, thead, tr {
        border: 1px solid;
        border-color: inherit;
        border-color: aliceblue;
    
    }



    /* mobile menu */
    .back_menu {
        display: block;
        color: #000000;
        padding: 8px 10px;
        border-bottom: 1px solid #d6dfe8;
        font-size: 17px;
    }
    .back_menu i {
        width: 20px;
        height: 20px;
        background-position: -212px -60px;
        display: inline-block;
        vertical-align: middle;
        background-size: 280px;
    }
    .back_menu img {
        width: 8px;
        height: 15px;
    }
    .mobilemenu {
        position: fixed;
        top: 56px;
        bottom: 0;
        left:100%;
        right: 0;
        z-index: 999;
        width: 80%;
        overflow: auto;
        background: #fff;
    }
    .mobilemenu.open {
        left: 0% !important;
        width: 99% !important;
        height: 50% !important;
    }
    .mobilemenu ul li {
        border-bottom: 1px solid #d7d7d7;
    }
    
    .mobilemenu ul li a, .mobilemenu ul li .noatag-need {
        color: #fff;
        display: block;
        padding: 10px;
        font-size: 14px;
    }
    .mobilemenu ul li .inner_menu ul li a {
        padding: 10px 15px;
    }
    /* .mobilemenu ul li .inner_menu ul li a.mob_active_link {
        color: #ff4a47;
        border-bottom: 1px solid #d7d7d7;
    } */
    .mobilemenu ul li.show-mobile {
        display: block;
    }
    .mobilemenu ul .toggle:after, .mobilemenu ul .toggle_sm:after {
        width: 12px;
        height: 15px;
        display: block;
        /* background: url(../images/svg/sprite.png) -133px -40px no-repeat; */
        background-size: 180px;
        content: "";
        float: right;
        margin: 3px 5px 0 0;
    }
    .mobilemenu .menu_logo.registerlogin {
        padding: 10px;
        background:lightskyblue;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .mobilemenu .menu_logo.registerlogin a {
        color: #fff;
        display: inline-block;
        border-right: 1px solid #fff;
        padding: 0 10px;
        font-size: 16px;
        line-height: 20px;
    }
    .mobilemenu .menu_logo.registerlogin a:last-child {
        border-right: none;
    }
    .mobilemenu .inner_menu, .mobilemenu .service-dropedown {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -100%;
        z-index: 9;
        width: 80%;
        overflow: auto;
        background: #fff;
    }
    .mobilemenu .wallet {
        display: none !important;
    }

    .menu .MyMenu > li > .noatag-need {
    padding: 20px 1px;
    }

    @media screen and (min-width: 1024px) {
    .back_menu {
        display: none;
    }
    /* .menu .MyMenu > li > a {
        font-size: 0.875rem;
        color: #ffffff;
        padding: 20px 3px;
        display: block;
    } */
    .menu .MyMenu > li > .noatag-need {
        font-size: 0.875rem;
        color: #ffffff;
        padding: 20px 3px;
        display: block;
    }
    }
    .mobilemenu .menu_logo.registerlogin a:last-child {
        border-right: none;
    }
    .mobilemenu .inner_menu, .mobilemenu .service-dropedown {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0% !important;
        z-index: 9;
        width: 99% !important;
        overflow: auto;
        background: white;
    }
    .mobilemenu{
        background-color: #0F172A !important;
        color: white;
    }
    .mobilemenu ul{
        list-style: none;
    }
    .toggle
    .mobilemenu .wallet {
        display: none !important;
    }
    .services_menu.open {
        left: 0%; /* Slide in from the right */
        height: 90%;
        top: 53px !important;
        width: 99%;
        animation:mainslideIn .5s steps(10) forwards;
      }
    .services_menu.menuclose {
        right: 20%; /* Slide in from the right */
        animation:mainslideIn .5s steps(10) forwards;
      }
      .services_menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      
      .services_menu ul li {
        padding: 10px 20px;
        border-bottom: 1px solid #e0e0e0;
      }
      
      .services_menu ul li:last-child {
        border-bottom: none;
      }
      
      /* Style for the toggle button */
      .toggle {
        cursor: pointer;
      }
      
      /* Style for links inside menu items */
      .MyMenu a {
        text-decoration: none;
        color: #000000; /* Link color */
      }
      
      /* Style for the active link (if needed) */
      .MyMenu a.active {
        font-weight: bold;
        color: #007bff; /* Active link color */
      }
      .mobilemenu ul li{
        color: white;
      }
      
      .lower-content h4{
        font-size: 34px;
      }
    .mainitem{
        display: none !important;
    }
    .mobilemenu ul li{
    color: black !important;
    }
    .menu .MyMenu > li > .noatag-need {
    padding: 20px 1px;
    }
    @media screen and (max-width: 1023px){
    .mobilemenu.open {
        left: 20%;
    }
    }
    .mobilemenu.open {
        -webkit-animation: mainslideIn .5s steps(10) forwards;
        animation: mainslideIn .5s steps(10) forwards;
        left: 20%;
    }

    @media (max-width: 768px){
    .mobilemenu.open {
        -webkit-animation: mainslideIn .5s steps(10) forwards;
        animation: mainslideIn .5s steps(10) forwards;
        left: 20%;
    }
    .center{
        text-align: center !important; 
        font-size: 15px !important;
    }
    .setpara
    {
        font-size: 12px;
    }
    .setgap{
        padding-top: 1px !important;
        padding-bottom: 2px !important;
    }
    .lower-content h4 {
        font-size: 28px !important;
    }
    .burger{
        display: block !important;
    }
    .mb-col{
        width: 0%;
    }

    .mb-r{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
    }
       .burger{
        display: none;
        font-size: 20px;
        color: white;
    }
    .mainitem{
        display: flex;
        width: 100%;
        justify-content: flex-end;
    
    }
    .mainitem{
        display: flex !important;
    }

    }
 
    .menu-overlay.active {
        background: rgba(gray,.8);
        height: 100%;
        left: 0;
        opacity: 1;
        position: fixed;
        top: 56px;
        transition: all .4s ease;
        width: 100%;
        z-index: 99;
    }
 


    .rating-stars {
        display: block;
        width: 100%;
     background-color: #000;
      
        border-radius: 5vmin;
        position: relative;
    }
    
    .rating-counter {
      display: none;
    }
    
    .rating-counter:before {
        content: "0";
        transition: all 0.25s ease 0s;	
    }
    
    
    
   
    .rounded-circle svg:hover{
     color:white;
     background-color: #011dff !important;
     transition: 1s linear;
    }
    label {
        width: 5vmin;
        height: 5vmin;
        background: #000b;
        display: inline-flex;
        cursor: pointer;
        margin: 0.5vmin 0.65vmin;
        transition: all 1s ease 0s;	
        clip-path: polygon(50% 0%, 66% 32%, 100% 38%, 78% 64%, 83% 100%, 50% 83%, 17% 100%, 22% 64%, 0 38%, 34% 32%);
    }
    
    label[for=rs0] {
        display: none;
    }
    
    label:before {
        width: 90%;
        height: 90%;
        content: "";
        background: orange;
        z-index: -1;
        display: block;
        margin-left: 5%;
        margin-top: 5%;
        clip-path: polygon(50% 0%, 66% 32%, 100% 38%, 78% 64%, 83% 100%, 50% 83%, 17% 100%, 22% 64%, 0 38%, 34% 32%);
        background: linear-gradient(90deg, blue, purple 30% 50%, #184580 50%, 70%, #173a75 100%);
        background-size: 205% 100%;
        background-position: 0 0;
    }
    
    label:hover:before {
        transition: all 0.25s ease 0s;		
    }
    
    input:checked + label ~ label:before {
        background-position: 100% 0;
        transition: all 0.25s ease 0s;	
    }
    
    input:checked + label ~ label:hover:before {
        background-position: 0% 0
    }
    
    
    
    
    
    #rs1:checked ~ .rating-counter:before {
        content: "1";
    }
    
    #rs2:checked ~ .rating-counter:before {
        content: "2";
    }
    
    #rs3:checked ~ .rating-counter:before {
        content: "3";
    }
    
    #rs4:checked ~ .rating-counter:before {
        content: "4";
    }
    
    #rs5:checked ~ .rating-counter:before {
        content: "5";
    }
    
    label + input:checked ~ .rating-counter:before {
        color: #ffab00 !important;
        transition: all 0.25s ease 0s;
    }
    
    
    
    
    
    label:hover ~ .rating-counter:before {
        color: #9aacc6 !important;
        transition: all 0.5s ease 0s;	
        animation: pulse 1s ease 0s infinite;
    }
    
    @keyframes pulse {
          50% { font-size: 6.25vmin; }
    }
    
    label[for=rs1]:hover ~ .rating-counter:before {
        content: "1" !important;
    }
    
    label[for=rs2]:hover ~ .rating-counter:before {
        content: "2" !important;
    }
    
    label[for=rs3]:hover ~ .rating-counter:before {
        content: "3" !important;
    }
    
    label[for=rs4]:hover ~ .rating-counter:before {
        content: "4" !important;
    }
    
    label[for=rs5]:hover ~ .rating-counter:before {
        content: "5" !important;
    }
    
    
    input:checked:hover ~ .rating-counter:before {
        animation: none !important;
        color: #ffab00 !important ;
    }
    .socail-icon{
        background-color: #fff;
        border-radius: 7px;
        padding: 7px;

        
    }
    .socail-icon svg{
        color: #000 !important;
    }
    .main-review-head{
        border-bottom: 2px solid blue;
        max-width: 30%;
        margin: 0 auto;

    }
    .review-select{
        margin: 0 auto;
        width: 40%;
    }
    .makeitinvisible{
        display: none;
    }
    .position-fixed{
        width: 100%;
        height: 180px;
        background-color: white;
        visibility: visible;
        z-index: 9999;
        position: fixed;
        bottom: 0;
    }
    .review-select select {
        padding-bottom: 2px;
        padding-top: 1px;
    }
    .text-sm {
        font-size: .875rem;
        line-height: 1.25rem;
    }
    
    .grid-cols-2 {
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
    .grid {
        display: grid;
    }
    .text-slate-600 {
        --tw-text-opacity: 1;
        color: rgb(0 0 0 / var(--tw-text-opacity));
    }
    .flex {
        display: flex;
    }
    .mr-4 {
        margin-right: 17px;
        text-align: left;
        font-size: 12px;
    
    }
    .bg-secondary-50 {
        --tw-bg-opacity: 1;
        background-color: rgb(237 243 253 / var(--tw-bg-opacity));
    }
    /* .ratings{
        margin-right:10px;
    } */
    
    .ratings i{
        
        color:#cecece;
        font-size:32px;
    }
    
    .rating-color{
        color:#011dff !important;
    }
    
    .review-count{
        font-weight:400;
        margin-bottom:2px;
        font-size:24px !important;
    }
    
    .small-ratings i{
      color:#cecece;   
    }
    
    .review-stat{
        font-weight:300;
        font-size:18px;
        margin-bottom:2px;
    }
  
    .bg-box {
        background: #fff;
    box-shadow: 0px 0px 21px 1px rgb(230 231 234);
    width: 92%;
    padding: 22px;
    }



    .ratings {
        position: relative;
        vertical-align: middle;
        display: inline-block;
        color: #fff;
        overflow: hidden;
    }
    .full-stars {
        position: absolute;
        left: 0;
        top: 0;
        white-space: nowrap;
        overflow: hidden;
        color: #011dff;
    }
    .empty-stars:before, .full-stars:before {
        content:"\2605\2605\2605\2605\2605";
        font-size: 14pt;
    }
    .empty-stars:before {
        -webkit-text-stroke: 1px #011dff;
    }
    .full-stars:before {
        -webkit-text-stroke: 1px #011dff;
    }
    /* Webkit-text-stroke is not supported on firefox or IE */
    
    /* Firefox */
     @-moz-document url-prefix() {
        .full-stars {
            color: #011dff;
        }
    }
    /* IE */

    @keyframes mainslideIn {
        0% {
          left: 100%;
        }
        100% {
          left: 20%;
        }
      }
      /* Custom CSS */
.border-slate-200 {
    border: 1px solid #E2E8F0; /* Replace with your desired border color */
}

.d-flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.d-lg-none {
    display: none;
}

.d-lg-flex {
    display: flex !important;
}

.d-lg-gap-4 {
    gap: 1rem !important;
}

.d-lg-justify-content-center {
    justify-content: center !important;
}

.d-lg-w-auto {
    width: auto !important;
}

.d-lg-block {
    display: block !important;
}

.font-weight-bold {
    font-weight: bold;
}

.text-capitalize {
    text-transform: capitalize;
}
.border-slate-200 {

    background: white;
    padding: 20px !important;
    border-bottom: 1px solid #8080803d !important;
}
/* Add your custom styling here as needed */
hr:not([size]) {
    height: 1px;
    width: 100%;
}
hr {
    margin: 0rem 0;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.25;
   
}
.fixed-div {
    position: fixed;
    top: 10%;
    width: 217px;
    left: 2%;
  }
  .left-2{
    left: 2%;
    position: relative;
  }
  .select-group{

    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 0px;
  }
  
.progress-bar {
    position: relative;
    height: 18px;
    width: 200px;
    margin-bottom: 1rem;
    background: white;
    border-radius: 25px;
    border: 2px solid white;

      box-shadow:5px 5px 10px rgba(0,0,0,.23);
  }
  
  .progress-fill {
    position: absolute;
    height: 18px;

    animation: progress-forward 3s infinite;
 
    border-radius: 15px;
  }
  .mainbar p{

    margin-bottom: 0rem;
    color: #000;
    font-weight: 600;
  }
  body{
    background-color: #F4F8FE;
  }
  .slide-wrap{
    background-color: white;
    padding: 20px;
    height: 1900px;
  }
  .shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow) !important;
}
.progress-fill{
    background-color: #08bb68;
}
.option-para{
    font-size: 12px;
}
.feeCalculator{
    background-color: #0F172A;
}
.feeCalculator-list{
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
}
.feeCalculator-list > li{
    font-size: 1rem;
    padding-top: 4px;
    line-height: 1.25rem !important;
}
.feeCalculator-new{
    background-color: #007bff;
    color: #ffffff;
    border-radius: 5px;
    font-size: 12px;

}
.feeCalculator-heading{
    font-size: 3rem;
    color: #ffffff;
}
.formarea{
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
    margin-top: 20px;
}
.BrokerFeeSection{
    background-color: #fff;
}
.BrokerFeeSection-para{
    color: grey;
}
.para-color{
    color: rgb(90, 90, 90);
}
.broker-fee{
    background-color: #e5e7eb;
    border-radius: 10px;
    padding: 2px;
    padding-inline: 20px;
    margin-top: 5px;
}
.percentage-para{
    font-size: 12px;
}
.calculate-broker{

    /* border: 0.6px solid rgb(167, 167, 167); */
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 8px;
}
.input-label{
    font-size: 14px;
    color: black;
    padding: 0px !important;
    margin: 3px !important;
}
.trade-input{
    text-align: end !important;
}
.ExchangeRate{
    background-color: #F4F8FE;
}
.ExchangeRate-para{
    color: grey;
}
.exchange-rate-div{
    border: 1px solid rgb(194, 194, 194);
    background-color: #fff;
    border-radius: 5px;
}
.broker-name{
    /* font-size: 3.5rem; */
    font-weight: 700;
}


.loading {
	text-align: center;
	width: min-content;
    position: absolute;
    /* width: 100%; */
    /* top: 100%; */
    /* left: 40%; */
}

.loading__text {
	font-weight: 500;
	font-size: 2rem;
}

.loading__bar {
	position: relative;
	height: 5px;
	width:1500px;
	background-color: #0F172A;
	border-radius: 1em;
	overflow: hidden;
    z-index: 1;
}

.loading__bar::after {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	width: 50%;
	height: 100%;
	/* // background-color: rgba(230, 230, 230, 0.891); */
	background: linear-gradient(90deg, #fff5, rgba(230, 230, 230, 0.891));
	animation: loading-animation 1.3s infinite;
	border-radius: 1em;
}

@keyframes loading-animation {
	0% {
		left: -50%;
	}

	100% {
		left: 150%;
	}
}
/* body{
    overflow-x: hidden;
} */
.shadow1 {
    box-shadow:5px 5px 10px rgba(0,0,0,.23)  
}
.faiz:hover{
    border-radius: 7px !important;
}
.nm{
    width: 450px;
}
.Loader {
    width: 200px;
    height: 10px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    margin: 100px auto;
    margin-top: 20%;
    margin-bottom: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.Loader:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 10px;
    width: 40%;
    background-color: blue;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
}
.posi{
    position:relative ;
    left:33%;
}.overflow-x-hidden {
    overflow-x: hidden;
  }
  .topBroker{
    margin-left: 16px !important;
}
.font-32{
    font-size: 32px !important;
}
  @media only screen and (max-width: 450px) {
    .type{
        font-size: 0.7rem !important;
    }
    .font-32{
        font-size: 20px !important;
    }
    .font-h6{
        font-size: 15px !important;
    }
    .tradepara{
        text-align: center !important;
    }
    .slide-wrap {
        padding: 10px !important;
        height: 100% !important;
        border-radius: 20px !important;
    }
    .Mobilebtn{
        font-size: 10px !important;
        /* padding:1px 2px;  */
        /* height:40px; */
        /* width:150px !important; */
        /* text-align:center; */
        /* border-radius:5px; */
    }
    .topBroker{
        margin-top: 10px !important;
        margin-left: 2px !important;
        font-size: 15px !important;
    }
    .feecont{
        padding: 30px !important;
    }
    .dropdwonleft{
        margin-left: -10px !important;
        /* width: 200px !important; */
        text-align: center !important;
        
    }
    .visits{
        
        margin-left: -40px !important;
        margin-top: 10px !important;
    }
    .mss-3{
        margin-top: 6px !important;
    }
.resFee{
    display: flex;
    flex-direction: column;
    
    justify-content: center !important;
}
.m{
    margin-top: 10px !important;
    
}
.new{
    text-align: start !important;
    margin-left: 40px !important;
    margin-top: 3px !important;
    margin-top: 20px !important;
    margin-bottom: 30px !important;
}
.new1{
    text-align: right !important;
    margin-top: -95px !important;
    margin-right: 20px !important;
}
.cur{
    margin-left: 20px !important;
}
.cur1{
    margin-right: 50px !important;
}
  }
  /* @media (max-width: 991.98px) {
   
  
    .formarea {
      overflow-x: visible;
    }
  }
   */
   
.whats{
    width: 300px;
}
@media (min-width: 992px) {
    .hi1{
        position: relative;
        left: 100%;
        /* bottom: -70% !important; */
    }
    .hi2{
        position: relative;
        top: 43px;
    }
    .bottum{
        /* margin-top: -50px !important; */
    }
    .bottum1{
        /* margin-top: -30px !important; */
    }
    .container-fluid-lg {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
    .ld-none{
        display: none !important;
    }
  }

    
  @media screen and (min-width :1400px  ) {
    .nestedmenu{
        background-color: #fff;
        position: absolute;
        top: 53px;
        right:4.5% !important;
       
        border-bottom-right-radius: 10px; 
        border-top: 1px solid #f1f1f1;
    visibility: hidden;
    z-index: 99999999;

    opacity: 0;
    height: auto;
    padding: 10px;
    width: auto;
    }
    .posi{
        position: relative !important;
        left: 34.8%  !important;
    } 
}
@media screen and (min-width :1600px ) {
    .posi{
        position: relative !important;
        left: 36.5%  !important;
    } 
}
.scam{
    width: 90%;
    border: 2px solid #cbd5e1;
}
.scam:focus{
    border: 2px solid #cbd5e1; 
    box-shadow: none;
    /* outline: none; */
}
.border1{
    border: 2px solid #cbd5e1; 
    /* border: none; */
    /* box-shadow: 2px 2px 10px #cbd5e1; */
    border-radius: 10px;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 40px;   
    height: 150px;
}
.scam-con{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 80px;
}
.re{
    font-size: 1.5rem;
    font-weight: 700;
}
@media screen and (max-width :500px ) {
    .scam-con{
        width: 100%;
        margin-left: 0px;
    }
    .heading-scam{
        font-size: 20px;
    }
    .border1{
        height: 100px;
        padding: 10px;
    }
    .re{
        font-size: 1.6rem;
        text-align: center;
    }
}
.hellof{
    margin-top: 130px;
}
@media (min-width: 1200px) {
    .cus-con{
        max-width: 1230px;
        display: flex;
        flex-direction: column;
        margin: 0 auto ;
        justify-content: center;
    }
    .cus-cone{
        max-width: 1250px;
        display: flex;
        flex-direction: column;
        margin: 0 auto ;
        justify-content: center;
    }
}